import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { useState, useEffect, useCallback, useContext } from 'react'
import useSession from 'lib/network/hooks/useSession'

import notify from 'lib/errors'
import { publishEvent } from 'lib/events/tracking'
import ProductContext from 'contexts/ProductContext'
import Event from 'types/Event'

declare global {
  interface Window {
    Kustomer: any
  }
}

export function getKustomerAssistantId(asPath: string) {
  return ['/products/'].includes(asPath) ? process.env.KUSTOMER_ASSISTANT_ID_PDP : ''
}

export function isKustomerWidgetOpen() {
  return JSON.parse(window.localStorage.getItem('kustomerState'))?.wasWidgetOpen
}

export function isAccountPage(asPath: string) {
  return ['/account/info', '/account/orders'].includes(asPath)
}

type KustomerLiveChatProps = {
  assistantId?: string
  persist?: boolean
} & KustomerChatStartProps

type KustomerChatStartProps = {
  chatIcon?: boolean
  chatWindow?: boolean
  user?: any
}

const useKustomerLiveChat = ({
  assistantId,
  chatIcon = false,
  chatWindow = false,
  persist = true,
}: KustomerLiveChatProps) => {
  const { asPath } = useRouter()
  const { product } = useContext(ProductContext)

  const [isChatAvailable, setIsChatAvailable] = useState<boolean>(true)
  const { data: session } = useSession()
  const { user: sessionUser } = session ?? {}
  const { publicRuntimeConfig } = getConfig() || {}
  const brandId = publicRuntimeConfig.KUSTOMER_TRACKING_BRAND_ID

  const handleCloseWidget = () => {
    window.Kustomer.getOpenConversations((conversations, error) => {
      if (error) {
        notify(error, {
          metadata: error,
          metadataName: 'Kustomer Chat Closed with Errors',
        })
      }

      if (conversations.length === 0) {
        setTimeout(() => {
          window.Kustomer.stop()
        }, 0)
      }
    })
  }

  const startKustomer = useCallback(
    ({
      chatIcon: showChatIcon,
      chatWindow: showChatWindow,
      user: providedUser,
    }: KustomerChatStartProps) => {
      const user = providedUser ?? sessionUser
      if (window && window.Kustomer) {
        window.Kustomer.start(
          {
            brandId,
            assistantId,
            zIndex: 12,
            hideChatIcon: !showChatIcon,
            chatIconPosition: {
              alignment: 'left',
              verticalPadding: 60,
              width: 0,
            },
          },
          () => {
            if (user?.kustomerJwtToken) {
              window.Kustomer.login({ jwtToken: user.kustomerJwtToken })
            }
            window.Kustomer.addListener('onConversationCreate', res => {
              window.Kustomer.describeConversation({
                conversationId: res.conversationId,
                customAttributes: {
                  referringUrl: asPath,
                  product: product?.displayName,
                },
              })

              publishEvent(Event.Kustomer.LIVE_CHAT_INITIATED)
            })
            window.Kustomer.addListener('onClose', handleCloseWidget)
            const { availability } = window.Kustomer.isChatAvailable()
            setIsChatAvailable(availability === 'online')

            if (showChatWindow && isChatAvailable) {
              window.Kustomer.open()
            }
          },
        )
      }
    },
    [asPath, assistantId, brandId, isChatAvailable, product?.displayName, sessionUser],
  )

  const handleStartKustomer = useCallback(
    async (props?: KustomerChatStartProps | null) => {
      if (window?.Kustomer?.stop) window.Kustomer.stop()

      startKustomer(props ?? { chatIcon, chatWindow })
    },
    [startKustomer, chatIcon, chatWindow],
  )

  useEffect(() => {
    const initKustomer = () => {
      handleStartKustomer({ chatIcon, chatWindow })
    }

    // The idea here is that we only initialize Kustomer when we really want it to show up.
    if (!window.Kustomer && chatIcon) {
      window.addEventListener('kustomerLoaded', initKustomer)
    }

    // If the widget was open before a refresh, we need to re-open it
    const wasWidgetOpen = isKustomerWidgetOpen()
    if (wasWidgetOpen) {
      handleStartKustomer({ chatIcon, chatWindow: wasWidgetOpen ? true : chatWindow })
    }

    return () => {
      if (!persist) {
        if (window?.Kustomer?.stop) {
          window.Kustomer.stop()
        }
        window.removeEventListener('kustomerLoaded', initKustomer)
      }
    }
  }, [handleStartKustomer, chatIcon, chatWindow, persist])

  return { isChatAvailable, handleStartKustomer, chatWindow, chatIcon }
}

export default useKustomerLiveChat
